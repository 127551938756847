import React, { Component } from "react";
import { navigate } from "gatsby";
import { useIntl } from "react-intl";
import axios from "axios";
import * as JsSearch from "js-search";
import { inject, observer } from "mobx-react";
import { distance } from "@turf/turf";

const withHook = (Component) => {
  return function WrappedComponent(props) {
    const intl = useIntl();
    return <Component {...props} intl={intl} />;
  };
};

// Injection du store Mobx (state management) pour partager les valeurs de recherches avec tous les composants
// Réactualisation automatique du composant avec @observer
@inject("store")
@observer
class Search extends Component {
  static today = new Date();
  static tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  static defaultStartDate = new Date(Search.today).toISOString().slice(0, 10);
  static defaultEndDate = new Date(Search.tomorrow).toISOString().slice(0, 10);

  constructor(props) {
    super(props);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.searchPlace = this.searchPlace.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleCouponCodeChange = this.handleCouponCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      hiddenQuery: props.hiddenQuery,
      preselectedQuery: props.preselectedQuery,
      preselectedBookingId: props.preselectedBookingId,
      currentLanguage: "fr",
      hotels: "",
      minEndDate: Search.defaultEndDate,
    };
  }

  componentDidMount() {
    this.startDate.min = Search.defaultStartDate;

    if (!this.props.store.startDate) {
      this.props.store.startDate = Search.defaultStartDate;
    }

    if (!this.props.store.endDate) {
      this.props.store.endDate = Search.defaultEndDate;
    }

    const startDate = new Date(this.props.store.startDate);
    const minEndDate = startDate.setDate(startDate.getDate() + 1);

    this.setState({
      minEndDate: new Date(minEndDate).toISOString().slice(0, 10),
    });

    this.rebuildIndex();
    // Si le composant est intégré sur la page d'un hôtel, alors la recherche est pré-remplie et le champ de recherche est masqué
    // Sinon, si le composant est intégré sur une autre page et que la valeur de preselectedQuery n'est pas vide, alors je pré-remplis la valeur de recherche avec l'hôtel passé en paramètre
    // Sinon je pré-remplis le champ avec la valeur de recherche précédente
    if (
      this.state.hiddenQuery === true ||
      this.state.preselectedQuery !== undefined
    ) {
      this.searchHotel(
        this.state.preselectedQuery,
        this.state.preselectedBookingId
      );
    }

    const places = [];

    this.props.store.hotelList.forEach((element) => {
      const newGeoJsonPoint = {
        type: "Feature",
        properties: {
          name: element.title,
          address: element.address,
          phone: element.phone,
          booking_id: element.booking_id,
          thumbnail: element.thumbnail,
          hero_image: element.hero_image,
          spirit: element.spirit,
          slug: element.slug,
          id: element.id,
        },
        geometry: {
          type: "Point",
          coordinates: [element.lon, element.lat],
        },
      };
      places.push(newGeoJsonPoint);
    });

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.hotels = {
      type: "FeatureCollection",
      features: places,
    };
  }

  /**
   * Query API MapBox Search
   */
  async getPlace() {
    return axios
      .get(
        `${process.env.GATSBY_MAPBOX_ENDPOINT_GEOCODING}${this.props.store.query}.json?access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}&autocomplete=true&country=fr&types=place%2Cpostcode&language=fr&limit=3&proximity=`
      )
      .then((result) => {
        this.props.store.firstPlace = result.data.features[0];
        this.props.store.features = result.data.features;
        return result.data.features;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * Query API MapBox Directions
   */
  getDuration = () => {
    if (this.props.store.firstPlace) {
      this.props.store.hotels.forEach((element) => {
        axios
          .get(
            `${process.env.GATSBY_MAPBOX_ENDPOINT_DIRECTIONS}${element.lon},${element.lat};${this.props.store.firstPlace.center[0]},${this.props.store.firstPlace.center[1]}?alternatives=false&geometries=geojson&steps=false&access_token=${process.env.GATSBY_MAPBOX_ACCESS_TOKEN}`
          )
          .then(({ data }) => {
            element["duration"] = Math.round(data.routes[0].duration / 60);
            element["firstPlace"] = this.props.store.firstPlace.text_fr;
          })
          .catch((error) => {
            console.error("Error Query API Directions", error);
            this.props.store.errorDuration = true;
          });
      });
    }
  };

  /**
   * Query search hotel
   *
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  async getHotels() {
    const { search, query } = this.props.store;
    this.props.store.hotels = search.search(query);
    return search.search(query);
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const { hotelList } = this.props.store;
    const dataToSearch = new JsSearch.Search("id");
    /**
     *  defines a indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

    dataToSearch.addIndex("address"); // sets the index attribute for the data
    dataToSearch.addIndex("title"); // sets the index attribute for the data
    dataToSearch.addIndex("tag"); // sets the index attribute for the data

    dataToSearch.addDocuments(hotelList); // adds the data to be searchedt
    this.props.store.search = dataToSearch;
    this.props.store.isLoading = false;
  };

  /**
   * Actualisation des résultats de recherche
   * @returns {Promise<void>}
   */
  async handleQueryChange() {
    this.props.store.query = this.search.value;
    this.props.store.searchQuery.value = this.search.value;
    this.props.store.searchQuery.type = "place";
    this.props.store.searchQuery.booking_id = "";

    // Auto-suggestion automatique à partir du 3ème caractère
    if (this.props.store.query.length > 2) {
      await Promise.all([this.getPlace(), this.getHotels()])
        .then((values) => {
          this.props.store.places = values[0];
          this.props.store.hotels = values[1];
        })
        .catch((e) => {
          console.error(e);
        })
        .then(() => {
          this.getDuration();
        });
    } else {
      this.props.store.hotels = [];
    }
  }

  /**
   * MAJ du store pour la date d'arrivée et de départ
   */
  handleStartDateChange() {
    this.props.store.startDate = this.startDate.value;

    const startDate = new Date(this.props.store.startDate);
    const minEndDate = startDate.setDate(startDate.getDate() + 1);

    this.setState({
      minEndDate: new Date(minEndDate).toISOString().slice(0, 10),
    });

    if (this.startDate.value > this.props.store.endDate) {
      this.props.store.endDate = new Date(
        startDate.setDate(startDate.getDate())
      )
        .toISOString()
        .slice(0, 10);
    }
  }

  handleEndDateChange() {
    this.props.store.endDate = this.endDate.value;
  }

  /**
   * MAJ du store pour le code promo
   */
  handleCouponCodeChange() {
    this.props.store.couponCode = this.couponCode.value;
  }

  /**
   * MAJ du store pour déclarer que la recherche est de type Lieu
   *
   * @param text
   * @param geometry
   */
  searchPlace(text, geometry) {
    this.search.value = text;
    this.props.store.searchQuery.value = text;
    this.props.store.searchQuery.type = "place";
    this.props.store.query = text;
    this.props.store.searchQuery.booking_id = "";
    this.handleQueryChange();

    /* Get the coordinate of the search place result */
    const searchResult = {
      type: "Point",
      coordinates: [geometry.coordinates[0], geometry.coordinates[1]],
    };

    /**
     * Calculate distances:
     * For each store, use turf.disance to calculate the distance
     * in kilometers between the searchResult and the state hotels. Assign the
     * calculated value to a property called `distance`.
     */
    const options = { units: "kilometers" };
    this.state.hotels.features.forEach(function (hotel) {
      Object.defineProperty(hotel.properties, "distance", {
        value: distance(searchResult, hotel.geometry, options),
        writable: true,
        enumerable: true,
        configurable: true,
      });
    });

    /**
     * Sort stores by distance from closest to the `searchResult`
     * to furthest.
     */
    this.state.hotels.features.slice().sort(function (a, b) {
      if (a.properties.distance > b.properties.distance) {
        return 1;
      }
      if (a.properties.distance < b.properties.distance) {
        return -1;
      }
      return 0; // a must be equal to b
    });

    const listResultHotel = [];
    if (this.props.store.hotels.length < 1) {
      this.state.hotels.features.forEach(function (el) {
        if (el.properties.distance < 50) {
          const properties = {
            id: el.properties.id,
            slug: el.properties.slug,
            title: el.properties.name,
            address: el.properties.address,
            phone: el.properties.phone,
            lon: el.geometry.coordinates[0],
            lat: el.geometry.coordinates[1],
            booking_id: el.properties.booking_id,
            spirit: el.properties.spirit,
          };
          listResultHotel.push(properties);
        }
      });
      this.props.store.searchResultsHotels = listResultHotel;
      if (listResultHotel.length > 0) {
        this.props.store.searchQuery.type = "distance";
      }
    }
  }

  /**
   * MAJ du store pour déclarer que la recherche est de type Hotel
   *
   * @param query
   * @param booking_id
   */
  searchHotel(query, booking_id) {
    this.search.value = "ACE Hôtel " + query;
    this.props.store.searchQuery.value = "ACE Hôtel " + query;
    this.props.store.searchQuery.type = "hotel";
    this.props.store.searchQuery.booking_id = booking_id;
  }

  /**
   * Détection de la soumission du formulaire
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();

    const url = new URL(event.target.action);

    if (url.hostname === "reservation.ace-hotel.com") {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "begin_checkout",
          event_category: "outbound",
          eventCallback: () => {
            event.target.submit();
          },
          eventTimeout: 2000,
        });
      } else {
        event.target.submit();
      }
    } else {
      // Si aucun hotel n'est associé à la ville recherché, alors on sélectionne l'hotel de plus proche de la ville recherché
      navigate(url.pathname);
      if (this.props.store.searchQuery.type !== "distance") {
        // Sinon on récupére la liste des hotels associés à la ville
        this.props.store.searchResultsHotels = this.props.store.hotels;
      }
    }
  }

  render() {
    const intl = this.props.intl;
    const { startDate, endDate, searchQuery } = this.props.store;
    const isHotelQuery = searchQuery.type === "hotel" && searchQuery.booking_id;

    const overnights =
      (new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24;

    let action = `/${intl.locale}/${
      intl.locale === "fr" ? "resultats" : "results"
    }/`;

    if (isHotelQuery) {
      action =
        "https://reservation.ace-hotel.com/module_booking_engine/index.php";
    }

    return (
      <form
        id="search"
        action={action}
        target={isHotelQuery ? "_blank" : undefined}
        className={
          this.state.hiddenQuery
            ? "form form-search form-search-hotel"
            : "form form-search"
        }
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <div className="form-field form-field-location" id="location">
          <input
            type="text"
            id="query"
            placeholder={intl.formatMessage({ id: "form.query" })}
            value={
              this.state.hiddenQuery === true ||
              this.state.preselectedQuery !== undefined
                ? this.state.preselectedQuery
                : this.props.store.searchQuery.value
            }
            required={true}
            ref={(input) => (this.search = input)}
            onChange={this.handleQueryChange}
          />
          <label htmlFor="query">
            {intl.formatMessage({ id: "form.query" })}
          </label>
          <ul id="form-results" className="dropdown">
            {this.props.store.places.map((place) => (
              <li key={place.id}>
                <button
                  className="dropdown-item"
                  type="button"
                  onMouseDown={() =>
                    this.searchPlace(place.text_fr, place.geometry)
                  }
                >
                  <span>{place.text_fr}</span>
                </button>
              </li>
            ))}
            {[
              ...this.props.store.hotels.sort(
                (a, b) => a.duration - b.duration
              ),
            ].map((hotel) => (
              <li key={hotel.booking_id}>
                <button
                  value={hotel.id}
                  className={`dropdown-item dropdown-item-${hotel.spirit.slug}`}
                  type="button"
                  onMouseDown={() =>
                    this.searchHotel(hotel.title, hotel.booking_id)
                  }
                >
                  <span>{`ACE Hôtel${
                    hotel.spirit.slug === "travel" ? " Travel" : ""
                  } ${hotel.title}`}</span>
                  <small>
                    {hotel.spirit.short_title} - {hotel.duration} min{" "}
                    {hotel.firstPlace}
                  </small>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="form-field form-field-dates">
          <div>
            <input
              type="date"
              id="startDate"
              placeholder={intl.formatMessage({ id: "form.arrival" })}
              value={this.props.store.startDate || Search.defaultStartDate}
              required
              min={Search.defaultStartDate}
              ref={(input) => (this.startDate = input)}
              onChange={this.handleStartDateChange}
            />
            <label htmlFor="startDate">
              {intl.formatMessage({ id: "form.arrival" })}
            </label>
          </div>
          <span aria-hidden="true">–</span>
          <div>
            <input
              type="date"
              id="endDate"
              placeholder={intl.formatMessage({ id: "form.departure" })}
              value={this.props.store.endDate || Search.defaultEndDate}
              required
              min={this.state.minEndDate}
              ref={(input) => (this.endDate = input)}
              onChange={this.handleEndDateChange}
            />
            <label htmlFor="endDate">
              {intl.formatMessage({ id: "form.departure" })}
            </label>
          </div>
        </div>
        <div className="form-field form-field-coupon-code">
          <input
            type="text"
            id="couponCode"
            name="code_promo"
            placeholder={intl.formatMessage({ id: "form.coupon-code" })}
            value={this.props.store.couponCode}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="none"
            spellCheck="false"
            ref={(input) => (this.couponCode = input)}
            onChange={this.handleCouponCodeChange}
          />
          <label htmlFor="couponCode">
            {intl.formatMessage({ id: "form.coupon-code" })}
          </label>
        </div>
        <button type="submit">{intl.formatMessage({ id: "book" })}</button>
        {isHotelQuery && (
          <>
            <input
              type="hidden"
              name="id_etab"
              value={searchQuery.booking_id}
            />
            <input
              type="hidden"
              name="date_deb"
              value={intl.formatDate(startDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            />
            <input type="hidden" name="nb_nuit" value={overnights} />
            <input
              type="hidden"
              name="langue"
              value={intl.locale === "fr" ? "francais" : "anglais"}
            />
          </>
        )}
      </form>
    );
  }
}

export default withHook(Search);
